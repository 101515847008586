import styled from 'styled-components';
import BasePopover from './BasePopover';

export const StyledPopover = styled(BasePopover)`
  background-color: ${({ theme }) => theme.palette.background.light};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: ${({ theme }) => theme.border.radius.standard}px;
  color: ${({ theme }) => theme.palette.text.main};

  .bp3-overlay-content:focus {
    outline: none;
  }

  .bp3-popover2-arrow,
  .bp3-popover2-arrow:before {
    width: 1.7rem;
    height: 1.1rem;
  }

  &.bp3-popover2-placement-right > .bp3-popover2-arrow {
    left: -6px !important;
  }

  &.bp3-popover2-placement-left > .bp3-popover2-arrow {
    right: -6px !important;
  }

  &.bp3-popover2-placement-bottom > .bp3-popover2-arrow {
    top: -5px !important;
  }

  &.bp3-popover2-placement-top > .bp3-popover2-arrow {
    bottom: 0px !important;
  }

  .bp3-popover2-arrow > svg {
    fill: white;
  }
`;
