import { MediaBreakpoints, MediaDirection } from 'src/hooks/types';
import { down, up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

const getMediaDirectionStrategy = (
  mediaDirection: MediaDirection
): typeof down | typeof up => {
  if (mediaDirection === 'up') {
    return up;
  }
  return down;
};

/**
 * Naming convention for properties come from our breakpoint theme names.
 * For more clarity on why we use UHD (4k) or QHD (2k) see the link below.
 * https://www.t3.com/us/features/wqhd-qhd-2k-4k-and-uhd-whats-the-difference
 */

const useBreakpoints = (
  mediaDirection: MediaDirection = 'down'
): MediaBreakpoints => {
  const breakpointStrategy = getMediaDirectionStrategy(mediaDirection);

  const isUhdScreen = useBreakpoint(breakpointStrategy('uhd'));
  const isQhdScreen = useBreakpoint(breakpointStrategy('qhd'));
  const isXxlScreen = useBreakpoint(breakpointStrategy('xxl'));
  const isXlScreen = useBreakpoint(breakpointStrategy('xl'));
  const isLgScreen = useBreakpoint(breakpointStrategy('lg'));
  const isMdScreen = useBreakpoint(breakpointStrategy('md'));
  const isSmScreen = useBreakpoint(breakpointStrategy('sm'));
  const isXsScreen = useBreakpoint(breakpointStrategy('xs'));

  return {
    isUhdScreen,
    isQhdScreen,
    isXxlScreen,
    isXlScreen,
    isLgScreen,
    isMdScreen,
    isSmScreen,
    isXsScreen,
  };
};

export default useBreakpoints;
