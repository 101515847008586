import { Typography } from '@statespacelabs/anthic-core';
import { lineClamp } from 'src/utils/styles';
import styled, { css } from 'styled-components';

export const StyledHeaderContainer = styled.div`
  margin: 1rem auto;
`;

export const StyledSectionName = styled(Typography)`
  text-align: left;
  padding: 0;
`;

export const StyledDefaultPrefix = styled.div`
  content: '';
  border: ${({ theme }) => theme.palette.text.light};
  height: 0.1rem;
  background: ${({ theme }) => theme.palette.text.light};
  width: 3rem;
  margin: 0.5rem 1rem 0.5rem 0;
`;

export const StyledSectionNameContainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    height: 1.6rem;
    width: 1.6rem;
    margin: 0rem 1rem 0rem 0;
  }
`;

export const StyledSubHeader = styled(Typography)<{
  $compact?: boolean;
  spacing: number;
  $oneline?: boolean;
}>`
  margin-top: ${({ spacing }) => `${spacing}rem`};
  text-align: left;
  line-height: ${({ $compact }) => ($compact ? 1 : '4.5rem')};

  ${({ $oneline }) =>
    $oneline &&
    css`
      list-style-position: inside;
      line-height: 1.5;
      ${lineClamp(1)};
    `}
`;
