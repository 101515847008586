import { memo, PropsWithChildren } from 'react';
import { DEFAULT_STACK_LAYOUT_GAP } from './constants';
import { StyledStackLayoutContainer } from './styles';
import { StackLayoutProps } from './types';

const StackLayout = ({
  gap = DEFAULT_STACK_LAYOUT_GAP,
  forwardedAs,
  children,
  ...props
}: PropsWithChildren<StackLayoutProps>): JSX.Element => (
  <StyledStackLayoutContainer $gap={gap} as={forwardedAs} {...props}>
    {children}
  </StyledStackLayoutContainer>
);

export default memo(StackLayout);
