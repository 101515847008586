import { BaseSyntheticEvent, memo, useCallback } from 'react';
import { FOOTER_LINK_CLICKED } from 'src/constants/analytics';
import { analytics } from 'src/utils/rudderInitialize';
import { aimlabPPUrl, aimlabTOSUrl, discordUrl } from './constants';
import DiscordLogo from './icDiscord.svg';
import InstagramIcon from './InstagramIcon.svg';
import RedditIcon from './RedditIcon.svg';
import {
  StyledBody,
  StyledCopyrightText,
  StyledDiscordImage,
  StyledDiscordLink,
  StyledDiscordText,
  StyledLogosLinks,
  StyledLogosRow,
  StyledLogosSubLinks,
  StyledPrivacyLink,
  StyledPrivacyLinks,
  StyledPrivacyText,
  StyledTextRowPrimary,
  StyledTextRowSecondary,
  StyledVerticalLine,
} from './styles';
import TwitterIcon from './TwitterIcon.svg';
import YoutubeIcon from './YoutubeIcon.svg';

const Footer = (): JSX.Element => {
  const trackHandler = useCallback((e: BaseSyntheticEvent) => {
    analytics.track(FOOTER_LINK_CLICKED, {
      link: e.target.href,
    });
  }, []);

  return (
    <StyledBody>
      <StyledLogosRow>
        <img
          src={`${process.env.PUBLIC_URL}/fullLogo.svg`}
          alt="Playerbase Logo"
        />
        <StyledLogosLinks>
          <StyledDiscordLink
            onClick={trackHandler}
            href={discordUrl}
            target="_blank"
            rel="noreferrer"
          >
            <StyledDiscordText>Join our</StyledDiscordText>
            <StyledDiscordImage src={DiscordLogo} alt="Discord" />
            <StyledVerticalLine />
          </StyledDiscordLink>
          <StyledLogosSubLinks>
            <a
              onClick={trackHandler}
              href="https://www.instagram.com/aimlab"
              target="_blank"
              rel="noreferrer"
            >
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a
              onClick={trackHandler}
              href="https://www.reddit.com/r/aimlab"
              target="_blank"
              rel="noreferrer"
            >
              <img src={RedditIcon} alt="Reddit" />
            </a>
            <a
              onClick={trackHandler}
              href="https://www.youtube.com/aimlab"
              target="_blank"
              rel="noreferrer"
            >
              <img src={YoutubeIcon} alt="Youtube" />
            </a>
            <a
              onClick={trackHandler}
              href="https://twitter.com/aimlab"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TwitterIcon} alt="Twitter" />
            </a>
          </StyledLogosSubLinks>
        </StyledLogosLinks>
      </StyledLogosRow>
      <StyledTextRowPrimary>
        <StyledPrivacyLinks>
          <StyledPrivacyText>
            All Rights Reserved {new Date().getFullYear()}
          </StyledPrivacyText>
          <StyledPrivacyLink color="light" href={aimlabPPUrl} rel="noreferrer">
            Privacy Policy
          </StyledPrivacyLink>
          <StyledPrivacyLink forwardedAs="a" color="light" href={aimlabTOSUrl}>
            Terms of Service{' '}
          </StyledPrivacyLink>
          <StyledPrivacyLink
            onClick={trackHandler}
            color="light"
            href="https://jobs.lever.co/statespacelabs"
            target="_blank"
          >
            Join Our Team
          </StyledPrivacyLink>
        </StyledPrivacyLinks>
        <StyledPrivacyLinks />
      </StyledTextRowPrimary>
      <StyledTextRowSecondary>
        <StyledCopyrightText>
          Aim Lab, Aim Lab logos, and other Aim Lab trademarks are trademarks or
          registered trademarks of State Space Labs, Inc. or its affiliates in
          the U.S. and elsewhere. Other names or brands are trademarks of their
          respective owners.
        </StyledCopyrightText>
      </StyledTextRowSecondary>
    </StyledBody>
  );
};

export default memo(Footer);
