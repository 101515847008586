import Crowdin, { Credentials } from '@crowdin/crowdin-api-client';
import iso6391 from 'iso-639-1';
import { crowdinKey, crowdinProjectId } from 'src/config';
import { Language } from 'src/graphql/types';
import i18n from 'src/utils/globalization/i18n';
import { DEFAULT_LANGUAGE } from './constants';
import { LanguageName } from './types';

export const getLanguageName = (language: Language): LanguageName => ({
  code: language,
  name: iso6391.getName(language),
  nativeName: iso6391.getNativeName(language),
});

export const getSupportedCrowdinLanguages = async (): Promise<
  LanguageName[]
> => {
  const credentials: Credentials = {
    token: crowdinKey || '',
  };

  const { projectsGroupsApi } = new Crowdin(credentials);
  const parsedProjectId = parseInt(crowdinProjectId || '', 10);
  const {
    data: { targetLanguageIds },
  } = await projectsGroupsApi.getProject(parsedProjectId);
  return targetLanguageIds.map((languageIds) =>
    getLanguageName(languageIds as Language)
  );
};

export const getPreferredLanguageNameOrDefault = (
  supportedLanguages: LanguageName[]
): LanguageName => {
  const preferredLanguage = i18n.language;
  const supportedPreferredLanguage = supportedLanguages.find(
    (language) => language.code === preferredLanguage
  );
  return supportedPreferredLanguage || getLanguageName(DEFAULT_LANGUAGE);
};

export const setPreferredLanguageInSessionStorage = (
  languageCode: Language
): void => {
  window.sessionStorage.setItem('i18nextLng', languageCode);
};
