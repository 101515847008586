import { Link, Typography } from '@statespacelabs/anthic-core';
import styled from 'styled-components';

export const StyledBody = styled.div`
  background: ${({ theme: { palette } }) => palette.background.main};
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { space } }) => `${space.spacing11} 0`};
  row-gap: 2rem;
`;
export const StyledLogosRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: initial;
    row-gap: 1rem;

    > img:first-child {
      align-self: center;
      max-width: 300px;
    }
  }
`;
export const StyledLogosLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    row-gap: 0.8rem;

    a {
      padding: 0rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 1024px) {
    > div > a:not(:last-child) {
      margin-right: 22px;
    }
  }
`;
export const StyledLogosSubLinks = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledDiscordLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;
export const StyledVerticalLine = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.palette.background.light};
`;
export const StyledDiscordText = styled(Typography)`
  color: ${({ theme }) => theme.palette.gray.light};
`;
export const StyledDiscordImage = styled.img`
  opacity: 0.48;
  margin-top: 3px;
  margin-right: 19px;
  margin-left: 10px;
`;
export const StyledTextRowPrimary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: initial;
    row-gap: 1rem;
  }
`;
export const StyledPrivacyLinks = styled.div`
  display: flex;
  font-size: 14px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
export const StyledPrivacyText = styled(Typography)`
  color: ${({ theme }) => theme.palette.gray.light};
  margin-right: 55px;
  margin-bottom: 0;
`;
export const StyledPrivacyLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 22px;
  }
`;
export const StyledTextRowSecondary = styled.div`
  @media (min-width: 1024px) {
    width: 50%;
  }
`;
export const StyledCopyrightText = styled(Typography)`
  margin: 0;
  font-weight: 300;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.gray.light};
  line-height: 21px;
`;
