import {
  Typography,
  DropdownMenu,
  DropdownMenuLabel,
} from '@statespacelabs/anthic-core';
import { memo, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  getSupportedCrowdinLanguages,
  LanguageName,
  getPreferredLanguageNameOrDefault,
  setPreferredLanguageInSessionStorage,
} from 'src/utils/globalization';
import {
  StyledContainer,
  StyledArrowTriangle,
  StyledLanguageContainer,
  StyledContentContainer,
  StyledLanguageItem,
} from './styles';

const NavigationLocalizationMenuItem = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [supportedLanguages, setSupportedLanguages] = useState<LanguageName[]>(
    []
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    getSupportedCrowdinLanguages().then((crowdinLanguages) => {
      setSupportedLanguages(crowdinLanguages);
    });
  }, []);

  const { code } = getPreferredLanguageNameOrDefault(supportedLanguages);
  const trigger = (
    <StyledLanguageContainer>
      <Typography color="main" variant="body2">
        {code.toUpperCase()}
      </Typography>
      <StyledArrowTriangle $open={isOpen} />
    </StyledLanguageContainer>
  );

  return (
    <StyledContainer gap="spacing3">
      <DropdownMenuLabel color="main" variant="textButton">
        Language
      </DropdownMenuLabel>
      <DropdownMenu
        trigger={trigger}
        content={
          <StyledContentContainer>
            {supportedLanguages.map((language) => {
              return (
                <StyledLanguageItem
                  onSelect={() => {
                    i18n.changeLanguage(language.code);
                    setIsOpen(!isOpen);
                    setPreferredLanguageInSessionStorage(language.code);
                  }}
                  $selected={i18n.resolvedLanguage === language.code}
                >
                  <Typography forwardedAs="li">
                    {language.nativeName}
                  </Typography>
                </StyledLanguageItem>
              );
            })}
          </StyledContentContainer>
        }
        color="light"
        sideOffset={12}
        open={isOpen}
        onOpenChange={(open: boolean) => {
          setIsOpen(open);
        }}
      />
    </StyledContainer>
  );
};

export default memo(NavigationLocalizationMenuItem);
