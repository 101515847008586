export const PRODUCT_VIEW_OPEN = 'Product View Open';
export const PRODUCT_VIEW_CLOSE = 'Product View Closed';
export const PRODUCT_PURCHASE_STARTED = 'Product Purchase Started';
export const PRODUCT_PURCHASE_COMPLETED = 'Product Purchase Completed';
export const PRODUCT_PURCHASE_CANCELLED = 'Product Purchase Cancelled';
export const PRODUCT_PURCHASE_ERRORED = 'Product Purchase Errored';
export const PRODUCT_SWITCH_STARTED = 'Product Switch Started';
export const PRODUCT_SWITCH_COMPLETED = 'Product Switch Completed';
export const PRODUCT_SWITCH_CANCELLED = 'Product Switch Cancelled';
export const PRODUCT_LOGIN_REQUIRED = 'Product Login Required';
export const PRODUCT_LOGIN_COMPLETED = 'Product Login Completed';
export const PRODUCT_WAITLIST_JOINED = 'Product Waitlist Joined';
export const PRODUCT_WAITLIST_LEFT = 'Product Waitlist Left';
export const PRODUCT_TEAM_CODE_REDEEM_STARTED =
  'Product Team Code Redeem Started';
export const PRODUCT_TEAM_CODE_REDEEM_SUCCEEDED =
  'Product Team Code Redeem Started';
export const PRODUCT_TEAM_CODE_REDEEM_FAILED =
  'Product Team Code Redeem Started';

// PRODUCT FILTERS
export const PRODUCT_FILTER_DROPDOWN_CLICK = 'Product Filter Dropdown Opened';
export const PRODUCT_FILTER_CHECKBOX_CLICK = 'Product Filter Checkbox Clicked';
export const PRODUCT_FILTER_FORM_CLEAR = 'Product Filters Cleared';
export const PRODUCT_FILTER_FORM_SUBMIT = 'Product Filters Applied';

export const HERO_BUTTON_CLICKED = 'Hero Button Clicked';
export const PAGINATION_CLICKED = 'Pagination Clicked';
export const APPLY_CLICKED = 'Apply Clicked';
export const CREATOR_CARD_CLICKED = 'Creator Card Clicked';
export const SEARCH_SUBMITTED = 'Search Submitted';
export const SEARCH_CREATOR_SELECTED = 'Search Creator Selected';
export const SEARCH_QUERIED = 'Search Queried';
export const FOOTER_LINK_CLICKED = 'Footer Link Clicked';
export const NAVIGATION_LINK_CLICKED = 'Navigation Link Clicked';
export const JOIN_CLICKED = 'Join Clicked';
export const LOGIN_CLICKED = 'Login Clicked';

export const CREATOR_HEADER_ABOUT_ME_EXPAND =
  'Creator Header About Me Expanded';
export const CREATOR_HEADER_ABOUT_ME_COLLAPSE =
  'Creator Header About Me Collapsed';
export const CREATOR_HEADER_SOCIAL_LINK_CLICKED = 'Social Link Clicked';
export const CREATOR_HEADER_VIDEO_PLAYED = 'Creator Video Played';
export const CREATOR_HEADER_VIDEO_CLOSED = 'Creator Video Closed';

export const FILTER_DROPDOWN_CLICK_OPEN = 'Filter Dropdown Opened';
export const FILTER_DROPDOWN_CLICK_CLOSE = 'Filter Dropdown Closed';
export const FILTER_CHECKBOX_CLICK = 'Filter Checkbox Clicked';
export const FILTER_FORM_CLEAR = 'Filters Cleared';
export const FILTER_FORM_SUBMIT = 'Filters Applied';

export const PROGUIDES_REDIRECT_LINK_CLICKED =
  'Proguides Redirect Link Clicked';
