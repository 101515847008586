import { Typography } from '@statespacelabs/anthic-core';
import { memo } from 'react';
import {
  StyledActionComponentContainer,
  StyledListItem,
  StyledListItemContentContainer,
} from './styles';
import { ListItemProps } from './types';

const ListItem: React.FC<ListItemProps> = ({
  actionComponent,
  header,
  content,
  children,
  ...listItemProps
}) => (
  <StyledListItem
    data-testid="list-item"
    tab-index="0"
    aria-label={header}
    {...listItemProps}
  >
    <StyledActionComponentContainer>
      {actionComponent}
    </StyledActionComponentContainer>
    <StyledListItemContentContainer>
      <Typography variant="textButton" color="dark">
        {header}
      </Typography>
      <Typography variant="subtitle" color="gray">
        {content}
      </Typography>
      {children}
    </StyledListItemContentContainer>
  </StyledListItem>
);

export default memo(ListItem);
