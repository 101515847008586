import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { StyledSubHeader } from 'src/components/anthic-core/Compounds/SectionHeader/styles';
import { MarkdownWrapperProps } from './types';

const MarkdownWrapper: FC<MarkdownWrapperProps> = ({
  $compact,
  subHeaderVariant,
  spacing = 1,
  markdown,
  color,
  components,
}) => {
  return (
    <ReactMarkdown
      skipHtml
      components={
        components || {
          ul: ({ children }) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              forwardedAs="ul"
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {children}
            </StyledSubHeader>
          ),
          li: ({ children }) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              forwardedAs="li"
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {children}
            </StyledSubHeader>
          ),
          p: ({ children }) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              forwardedAs="p"
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {children}
            </StyledSubHeader>
          ),
        }
      }
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default MarkdownWrapper;
