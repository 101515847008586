/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from 'oidc-client';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from './context';
import { AuthUserResult } from './types';

// TODO: Clean up return type as it was added to make linting pass
export const useAuthorization = (): {
  login: () => Promise<void> | undefined;
  loginResponse: (callback: any) => Promise<User> | undefined;
  loginRedirectTo: (redirectTo?: any) => void;
  logout: () => Promise<void> | undefined;
  logoutResponse: (callback: any) => Promise<void> | undefined;
  signup: () => Promise<void> | undefined;
} => {
  const { client } = useContext(AuthContext);
  const history = useHistory();

  const logout = useCallback(() => client?.signoutRedirect(), [client]);

  const login = useCallback(
    () =>
      client
        ?.clearStaleState()
        .then(() => client.removeUser())
        .then(() => client.signinRedirect()),
    [client]
  );

  const signup = useCallback(
    () =>
      client?.signinRedirect({
        extraQueryParams: { flow: 'signup' },
      }),
    [client]
  );

  const loginResponse = useCallback(
    (callback) => client?.signinRedirectCallback().then(callback),
    [client]
  );

  const logoutResponse = useCallback(
    (callback) =>
      client
        ?.signoutRedirectCallback()
        .then(() => client?.clearStaleState())
        .then(() => client?.removeUser())
        .then(callback),
    [client]
  );

  const loginRedirectTo = useCallback(
    (redirectTo = null) => {
      sessionStorage.setItem(
        'lastVisitedUrl',
        redirectTo || history.location.pathname
      );
      history.push('/login');
    },
    [history]
  );

  return {
    login,
    loginResponse,
    loginRedirectTo,
    logout,
    logoutResponse,
    signup,
  };
};

export const useAuthUser = (): AuthUserResult => {
  const { isAuthenticated, user } = useContext(AuthContext);

  return { isAuthenticated, user };
};
