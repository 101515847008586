import { Avatar } from '@statespacelabs/anthic-core';
import AimbloxLogo from './assets/aimblox-logo-symbol@2x.png';
import AimlabLogoBlack from './assets/aimlab-logo-black.svg';
import PlayerbaseLogoBlack from './assets/playerbase-logo-black.svg';
import ValorantLogo from './assets/valorant-logo-black.svg';
import { MenuItemsProps } from './types';

export const creatorScopes = [
  'Creator',
  'CreatorBundles',
  'CreatorBundleItems',
  'CreatorItems',
  'CreatorUsers',
  'CreatorOwners',
  'CreatorPage',
];

export const TOOLS_MENU_ITEM_PROPS: MenuItemsProps[] = [
  {
    actionComponent: <Avatar alt="Playerbase Logo" src={PlayerbaseLogoBlack} />,
    header: 'Playerbase Coaching',
    content: 'Learn from the best',
    href: '/',
  },
  {
    actionComponent: <Avatar alt="Aimlab Logo" src={AimlabLogoBlack} />,
    header: 'Aim Lab',
    content: 'AI-based training platform',
    href: 'https://aimlab.gg',
  },
  {
    actionComponent: <Avatar alt="Aimblox Logo" src={AimbloxLogo} />,
    header: 'Aimblox',
    content: 'Enhanced aim training in Roblox',
    href: 'https://www.roblox.com/games/6808416928/LEGENDARY-Aimblox-BETA',
  },
];

export const GAME_PROFILES_MENU_ITEM_PROPS: MenuItemsProps[] = [
  {
    actionComponent: <Avatar alt="Aimlab Logo" src={AimlabLogoBlack} />,
    header: 'Aim Lab',
    content: 'Aim Lab Data Profile',
    href: 'https://aimlab.gg/profile',
  },
  {
    actionComponent: <Avatar alt="Valorant Logo" src={ValorantLogo} />,
    header: 'Valorant',
    content: 'Valorant Data Profile',
    href: 'https://aimlab.gg/profile/valorant',
  },
];
