import styled from 'styled-components';

export const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 3rem;

  &:first-of-type {
    margin-top: unset;
  }
`;

export const StyledActionComponentContainer = styled.div`
  margin-right: ${({ theme }) => theme.space.spacing3};
`;

export const StyledListItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
