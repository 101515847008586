import { Button, Typography, Divider } from '@statespacelabs/anthic-core';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const StyledBackground = styled.div`
  background-color: ${({ theme: { palette } }) => palette.background.main};
  box-shadow: ${({ theme: { shadows } }) => shadows.shadow1};
  width: 100%;
`;

export const StyledWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-items: flex-start;
  position: relative;
  padding: ${({ theme: { space } }) => `${space.spacing5} 0`};
  overflow: hidden;

  ${up('lg')} {
    padding: ${({ theme: { space } }) => `${space.spacing7} 0`};
  }
`;

export const StyledLogo = styled.img`
  height: 26px;
  width: auto;

  ${up('sm')} {
    height: 30px;
  }

  ${up('lg')} {
    height: auto;
    width: 189px;
  }
`;

export const StyledMenu = styled.ul<{ $isOpen: boolean }>`
  background-color: ${({ theme }) => theme.palette.background.main};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  list-style: none;
  margin: 0;
  padding: ${({ theme: { space } }) => `${space.spacing12} ${space.spacing9}`};
  position: fixed;
  right: 0;
  top: 7.4rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 7.4rem);
  width: 50vw;
  transition: transform 0.2s ease-in-out;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  z-index: 51;

  ${up('md')} {
    width: 40vw;
  }

  ${up('lg')} {
    padding: 0;
    display: block;
    position: static;
    flex-direction: row;
    background-color: transparent;
    box-shadow: none;
    height: auto;
    width: auto;
    transform: none;
    transition: none;
  }
`;

export const StyledItem = styled(Typography)`
  display: block;
  width: 100%;
  padding: ${({ theme: { space } }) => space.spacing3};

  ${up('lg')} {
    display: inline-block;
    width: auto;
    margin-left: ${({ theme: { space } }) => space.spacing8};
    padding: 0;
  }
`;
export const StyledOptions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  margin-left: ${({ theme: { space } }) => space.spacing4};
`;

export const StyleMenuIcon = styled.span`
  display: inline-block;
  width: 2.2rem;
  height: 2.2rem;
  & svg {
    width: 2.2rem;
    height: 2.2rem;
    fill: #fff;
  }
  margin-left: ${({ theme: { space } }) => space.spacing4};
`;

export const StyleMobileMenuIcon = styled(StyleMenuIcon)`
  z-index: 1000;

  ${up('lg')} {
    display: none;
  }
`;

export const StyledNavigationPopoverListItem = styled.a`
  margin-top: ${({ theme: { space } }) => space.spacing7};
  text-decoration: none;

  &:first-of-type {
    margin-top: unset;
  }
`;

export const StyledNavigationItemTypography = styled(Typography)`
  cursor: pointer;
`;

export const StyledMobileLanguageDropdownContainer = styled.div`
  margin: 0 auto;
`;

export const StyledDivider = styled(Divider)`
  margin: ${({ theme: { space } }) => `${space.spacing9} 0`};
`;
