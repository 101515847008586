import { useEffect, useState } from 'react';
import { ZendeskAPI } from 'react-zendesk';
import { ZendeskApiFunction } from './zendesk-api.types';

export const useZendeskApi = (): [ZendeskApiFunction, boolean] => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const apiReadyIntervalId = setInterval(() => {
      if ('zE' in window) {
        setIsReady(true);
        clearInterval(apiReadyIntervalId);
      }
    }, 500);

    return () => {
      clearInterval(apiReadyIntervalId);
    };
  }, []);

  return [ZendeskAPI, isReady];
};
