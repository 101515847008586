import { Space, Theme } from '@statespacelabs/anthic-core/dist/theme/types';
import { CssUnit, LayoutGap } from 'src/typings/units';

export const resolveLayoutGap = (gap: LayoutGap, theme: Theme): CssUnit => {
  const gapIsThemeSpaceName = gap in theme.space;
  if (gapIsThemeSpaceName) {
    const themeSpaceName = gap as keyof Space;
    return theme.space[themeSpaceName];
  }

  return gap as CssUnit;
};
