import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

const Routes = (): JSX.Element => (
  <Switch>
    <Route
      path="/games/:gameId"
      render={({ match: { params } }) =>
        redirectTo(
          gameRoutes.get(params.gameId) ?? 'https://proguides.com/dashboard'
        )
      }
    />
    <Route
      path="/games"
      render={() => redirectTo('https://proguides.com/dashboard')}
    />
    <Route
      path="/coaches"
      render={() => redirectTo('https://proguides.com/coaches')}
    />

    <Route path="/login" render={() => redirectTo('https://proguides.com')} />
    <Route path="*" render={() => redirectTo('https://proguides.com/')} />
  </Switch>
);

export default memo(Routes);

const redirectTo = (href: string): null => {
  const url = new URL(href);
  url.searchParams.set('from', window.location.href);

  window.location.href = url.toString();
  return null;
};

const dash = 'https://proguides.com/dashboard';
const gameRoutes = new Map<string, string>([
  ['df0e233e-cad3-44be-8f9c-9719a2d8c3f5', `${dash}/aimlab`],
  ['8ab6eb49-aef6-4930-8469-ba967919428d', `${dash}/apex-legends`],
  ['07d9219c-439a-4953-9077-f4460886470d', `${dash}/cod-cold-war`],
  ['ab97440d-ebc4-41ad-a5df-fd113112c61e', `${dash}/csgo`],
  ['1050fafa-2430-43e9-8e54-a7720d5cd69e', `${dash}/destiny2`],
  ['c19fbcf5-5371-438f-93c7-4c531a2bb846', `${dash}/dota2`],
  ['b06a8e0b-052a-4e96-a62a-eb88c6cfd913', `${dash}/fortnite`],
  ['a251fa6e-6b18-4d78-be2d-79db252c7d89', `${dash}/halo-infinite`],
  ['35e0496c-8b2e-494d-bf2c-28ae632bf7f3', `${dash}/hearthstone`],
  ['c5b83b67-1417-44ac-94eb-0dc252846951', `${dash}/leagueoflegends`],
  ['d8ffce2b-aa5b-4fa3-aece-c85b9a90575f', `${dash}/magic-the-gathering`],
  ['270e1397-3ad5-4062-89e8-486a3dd2b8de', `${dash}/overwatch`],
  ['63534e53-479b-4e12-84aa-fdc7d7d359b3', `${dash}/rainbow-six-siege`],
  ['f99e98f0-11c6-4dba-a0e1-76f4097e3aab', `${dash}/rocket-league`],
  ['73ba3e5b-47d2-4c96-9590-6e48524360bb', `${dash}/super-smash-bros-ultimate`],
  ['3d498efd-d1db-4990-bd7e-aa1c19f32393', `${dash}/teamfight-tactics`],
  ['a1327e00-ec4d-4d58-9249-bb088754740e', `${dash}/valorant`],
]);
