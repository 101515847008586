import { Button } from '@statespacelabs/anthic-core';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const StyledSection = styled.section`
  margin: 0 auto;
`;

export const StyledParagraph = styled.div`
  padding: 1rem 0 2rem 0;
`;

export const StyledBackground = styled.div`
  background-color: ${({ theme: { palette } }) => palette.background.dark};
  border-radius: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  overflow: hidden;

  ${down('md')} {
    flex-direction: column-reverse;
  }
`;

export const StyledContent = styled.div`
  width: 50%;
  flex: 1 1 auto;
  padding: 2rem 2rem 2rem 12rem;
  height: 100%;

  ${down('md')} {
    width: 100%;
    padding: 0 1.6rem 1.6rem 1.6rem;

    & > a {
      width: 100%;
    }
  }
`;

export const StyledImage = styled.img`
  clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
  max-height: 50rem;

  width: 50%;

  ${down('md')} {
    width: 100%;
    max-height: none;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);
  }
`;

export const StyledButton = styled(Button)`
  width: 21rem;
`;
