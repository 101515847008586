import { memo, useCallback, useEffect, useMemo } from 'react';
import Zendesk from 'react-zendesk';
import { zendeskKey } from 'src/config';
import { useTheme } from 'styled-components';
import { analytics } from 'src/utils/rudderInitialize';
import { CHAT_TAGS, WEB_WIDGET_SETTINGS } from './constants';
import { createZendeskSettings, prefillCurrentUser } from './helpers';
import { useZendeskApi } from './hooks';
import { ZendeskChatWidgetProps } from './types';

const ZendeskChatWidget = ({
  currentUser,
}: ZendeskChatWidgetProps): JSX.NullableElement => {
  const theme = useTheme();

  const zendeskSettings = useMemo(() => {
    return createZendeskSettings(theme, WEB_WIDGET_SETTINGS);
  }, [theme]);

  const [zendeskApi, zendeskApiIsReady] = useZendeskApi();

  const prefillUserContactDetails = useCallback(() => {
    // User can see/edit their info when they launch the chat
    // and click "..." > "Edit contact details"
    if (zendeskApiIsReady && currentUser) {
      prefillCurrentUser(currentUser, zendeskApi);
    }
  }, [currentUser, zendeskApi, zendeskApiIsReady]);

  const handleZendeskLoaded = useCallback(() => {
    zendeskApi('webWidget', 'chat:addTags', CHAT_TAGS);

    zendeskApi('webWidget:on', 'userEvent', (event): void => {
      // @ts-ignore
      analytics.track(event.action, {
        category: event.category,
        properties: event.properties,
      });
    });
  }, [zendeskApi]);

  useEffect(() => {
    prefillUserContactDetails();
  }, [prefillUserContactDetails]);

  useEffect(() => {
    if (!zendeskKey) {
      console.warn('Key missing. Chat widget not loaded');
    }
  }, []);

  if (!zendeskKey) {
    return null;
  }

  return (
    <Zendesk
      defer
      zendeskKey={zendeskKey}
      {...zendeskSettings}
      onLoaded={handleZendeskLoaded}
    />
  );
};

export default memo(ZendeskChatWidget);
