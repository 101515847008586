import { memo, useMemo } from 'react';
import { useAuthUser } from 'src/utils/Auth';
import { createChatUserFromAuthUser } from './helpers';
import { ChatUser } from './types';
import ZendeskChatWidget from './ZendeskChatWidget';

const ZendeskChatWidgetContainer = (): JSX.Element => {
  const { user } = useAuthUser();

  const currentUser = useMemo<ChatUser | undefined>(() => {
    return user ? createChatUserFromAuthUser(user) : undefined;
  }, [user]);

  return <ZendeskChatWidget currentUser={currentUser} />;
};

export default memo(ZendeskChatWidgetContainer);
