import { getContrastText } from '@statespacelabs/anthic-core';
import { Theme } from '@statespacelabs/anthic-core/dist/theme/types';
import { User } from 'oidc-client';
import { ChatUser } from './types';
import { ZendeskApiFunction } from './zendesk-api.types';
import { WebWidgetSettings, ZendeskSettings } from './zendesk-settings.types';

export const createZendeskSettings = (
  theme: Theme,
  webWidgetSettings: WebWidgetSettings = {}
): ZendeskSettings => {
  const { palette } = theme;

  return {
    webWidget: {
      color: {
        theme: palette.secondary.main,
        launcher: palette.secondary.main,
        launcherText: getContrastText(theme, palette.secondary.main),
        button: palette.primary.main,
        header: palette.secondary.main,
        resultLists: palette.secondary.main,
        articleLinks: palette.secondary.main,
      },
      ...webWidgetSettings,
    },
  };
};

export const prefillCurrentUser = (
  { name, email }: ChatUser,
  zendeskApi: ZendeskApiFunction
): void => {
  zendeskApi('webWidget', 'prefill', {
    name: {
      value: name,
      readOnly: true,
    },
    email: {
      value: email,
      readOnly: true,
    },
  });
};

export const createChatUserFromAuthUser = ({
  profile: { username: name, email },
}: User): ChatUser => ({
  name,
  email: email || '',
});
