import { memo } from 'react';
import { StyledDefaultPrefix } from './styles';
import { SectionHeaderPrefixProps } from './types';

const SectionHeaderPrefix = ({
  prefixElement,
}: SectionHeaderPrefixProps): JSX.NullableElement => {
  if (prefixElement === null) {
    return null;
  }

  return prefixElement || <StyledDefaultPrefix />;
};

export default memo(SectionHeaderPrefix);
