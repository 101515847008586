import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { down } from 'styled-breakpoints';

const commonBefore = css`
  width: 1.5rem;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
`;

export const StyledToastContainer = styled(ToastContainer).attrs({})`
  --toastify-toast-width: 60rem;

  ${down('xs')} {
    --toastify-toast-width: 35rem;
  }

  .Toastify__toast {
    border-radius: 1.6rem;
    border: 0.1rem solid ${({ theme }) => theme.palette.info.main};
    &:before {
      ${commonBefore}
      background: ${({ theme }) => theme.palette.info.main};
    }

    & circle {
      fill: ${({ theme }) => theme.palette.info.main};
    }
  }

  .Toastify__toast--error {
    border: 0.1rem solid ${({ theme }) => theme.palette.error.main};
    &:before {
      ${commonBefore}
      background: ${({ theme }) => theme.palette.error.main};
    }

    & circle {
      fill: ${({ theme }) => theme.palette.error.main};
    }
  }

  .Toastify__toast--warning {
    border: 0.1rem solid ${({ theme }) => theme.palette.warning.main};
    &:before {
      ${commonBefore}
      background: ${({ theme }) => theme.palette.warning.main};
    }

    & circle {
      fill: ${({ theme }) => theme.palette.warning.main};
    }
  }

  .Toastify__toast--success {
    border: 0.1rem solid ${({ theme }) => theme.palette.success.main};
    &:before {
      ${commonBefore}
      background: ${({ theme }) => theme.palette.success.main};
    }
    & circle {
      fill: ${({ theme }) => theme.palette.success.main};
    }
  }

  .Toastify__toast-body {
    padding-left: 1.5rem;
  }

  .Toastify__toast-icon {
    width: 3rem;
  }
`;

export const StyledIconContainer = styled.div`
  width: 3rem;
  height: 3rem;
  & > svg {
    width: 3rem;
    height: 3rem;
  }
`;
