import { DropdownMenuItem } from '@statespacelabs/anthic-core';
import { StackLayout } from 'src/components/anthic-core/Compounds/StackLayout';
import { ArrowTriangle } from 'src/components/Icon';
import styled from 'styled-components';
import { StyledArrowTriangleProps, StyledLanguageItemProps } from './types';

export const StyledContainer = styled(StackLayout)``;

export const StyledLanguageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { space } }) => space.spacing4};

  :hover {
    cursor: pointer;
  }
`;

export const StyledArrowTriangle = styled(
  ArrowTriangle
)<StyledArrowTriangleProps>`
  width: 0.8rem;
  height: 0.8rem;

  transition: transform 180ms ease-in-out;
  transform: ${({ $open }) => ($open ? 'rotate(0deg)' : 'rotate(180deg)')};

  :hover {
    cursor: pointer;
  }
`;

export const StyledContentContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${({ theme: { space } }) => `${space.spacing3} 0`};
  row-gap: ${({ theme: { space } }) => space.spacing3};
  min-width: 150px;
`;

export const StyledLanguageItem = styled(
  DropdownMenuItem
)<StyledLanguageItemProps>`
  text-transform: capitalize;
  background-color: ${({ $selected, theme: { palette } }) =>
    $selected ? palette.gray.light : ''};
`;
