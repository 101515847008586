import * as analytics from 'rudder-sdk-js';

analytics.load(
  process.env.REACT_APP_RUDDERSTACK_KEY,
  process.env.REACT_APP_RUDDERSTACK_DATA_PLANE,
  {
    integrations: { All: true },
  }
);
export { analytics };
