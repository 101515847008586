import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import { authClientId, authHost, isProduction } from 'src/config';

export const createAuthService = (): UserManager => {
  const host = `${window.location.protocol}//${window.location.host}`;
  const userManager = new UserManager({
    authority: `${authHost}/oauth`,
    client_id: authClientId,
    redirect_uri: `${host}/login-success`,
    post_logout_redirect_uri: `${host}/logout-success`,
    response_type: 'code',
    prompt: 'consent',
    scope:
      'openid offline_access user user.profile profiles creator.page admin.creators',
    popupWindowTarget: '_self',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    revokeAccessTokenOnSignout: true,
    automaticSilentRenew: true,
  });

  Log.logger = console;
  Log.level = !isProduction ? Log.DEBUG : Log.ERROR;

  return userManager;
};
