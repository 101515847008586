import { StyledLayoutBoundary } from './styles';
import { LayoutBoundaryProps } from './types';

const LayoutBoundary = ({
  children,
  margin,
  ...rest
}: LayoutBoundaryProps): JSX.Element => {
  return (
    <StyledLayoutBoundary $margin={margin} {...rest}>
      {children}
    </StyledLayoutBoundary>
  );
};

export default LayoutBoundary;
