import { Popover2 } from '@blueprintjs/popover2';
import { FC } from 'react';
import { PopoverProps } from './types';

/**
 * Styled components "styled" fn applies the defined styles to the "className" prop.
 * By defining a custom component, we can redirect the className styles to the popover,
 * NOT the renderTarget.
 */
const Popover: FC<PopoverProps> = ({
  className,
  modifiers = { arrow: { enabled: true } },
  ...props
}) => (
  <Popover2 {...props} modifiers={modifiers} popoverClassName={className} />
);

export default Popover;
