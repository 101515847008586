/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Need to address usages of `any`
import { FC, memo } from 'react';
import MarkdownWrapper from 'src/components/anthic-core/Units/MarkdownWrapper';
import SectionHeaderPrefix from './SectionHeaderPrefix';
import {
  StyledHeaderContainer,
  StyledSectionName,
  StyledSectionNameContainer,
  StyledSubHeader,
} from './styles';
import { SectionHeaderProps } from './types';

const SectionHeader: FC<SectionHeaderProps> = ({
  prefixElement,
  sectionName,
  sectionNameColor,
  headline,
  color,
  headerVariant,
  subHeaderVariant,
  $compact,
  spacing = 1,
  renderMarkdown,
}) => (
  <StyledHeaderContainer>
    <StyledSectionNameContainer>
      <SectionHeaderPrefix prefixElement={prefixElement} />
      {sectionName && (
        <StyledSectionName
          forwardedAs="h3"
          variant={headerVariant || 'caption'}
          color={sectionNameColor || 'light'}
        >
          {sectionName}
        </StyledSectionName>
      )}
    </StyledSectionNameContainer>
    {headline && renderMarkdown && (
      <MarkdownWrapper
        markdown={headline?.split('\n')[0] || ''}
        components={{
          li: (props: any) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              forwardedAs="li"
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {props.children}
            </StyledSubHeader>
          ),
          ul: (props: any) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              forwardedAs="ul"
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {props.children}
            </StyledSubHeader>
          ),
          p: (props: any) => (
            <StyledSubHeader
              $compact={$compact}
              color={color || 'light'}
              variant={subHeaderVariant || 'header'}
              spacing={spacing}
              $oneline
            >
              {props.children}
            </StyledSubHeader>
          ),
        }}
      />
    )}
    {headline && !renderMarkdown && (
      <StyledSubHeader
        $compact={$compact}
        color={color || 'main'}
        forwardedAs="h2"
        variant={subHeaderVariant || 'header'}
        spacing={spacing}
      >
        {headline}
      </StyledSubHeader>
    )}
  </StyledHeaderContainer>
);

export default memo(SectionHeader);
