import { Typography } from '@statespacelabs/anthic-core';
import { useCallback } from 'react';
import SectionHeader from 'src/components/anthic-core/Compounds/SectionHeader';
import { APPLY_CLICKED } from 'src/constants/analytics';
import { analytics } from 'src/utils/rudderInitialize';
import {
  StyledBackground,
  StyledButton,
  StyledContent,
  StyledImage,
  StyledParagraph,
  StyledSection,
} from './styles';

const ApplySection = (): JSX.Element => {
  const clickHandler = useCallback(() => {
    analytics.track(APPLY_CLICKED);
  }, []);
  return (
    <StyledSection id="apply">
      <StyledBackground>
        <StyledContent>
          <SectionHeader
            sectionName="Apply To Playerbase"
            headline="Get Paid To Help Other Players"
          />

          <StyledParagraph>
            <Typography color="main">
              Want to earn money while doing what you love? Apply to join the
              Playerbase program and get notified when we roll out to more
              people.
            </Typography>
          </StyledParagraph>

          <StyledButton
            forwardedAs="a"
            href="https://aimlabgg.typeform.com/to/qBtJQNSu"
            target="_blank"
            rel="noreferrer"
            color="secondary"
            onClick={clickHandler}
          >
            Apply Now
          </StyledButton>
        </StyledContent>
        <StyledImage
          src={`${process.env.PUBLIC_URL}/static/playerbase/apply-1024x681.webp`}
          alt="Apply to Playerbase"
        />
      </StyledBackground>
    </StyledSection>
  );
};

export default ApplySection;
