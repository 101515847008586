import { Loader } from '@statespacelabs/anthic-core';
import { User, UserManager } from 'oidc-client';
import { createContext, FC, useEffect, useState } from 'react';
import { analytics } from 'src/utils/rudderInitialize';

export const AuthContext = createContext<{
  isAuthenticated: boolean;
  user: User | undefined;
  client: UserManager | null;
}>({
  isAuthenticated: false,
  user: undefined,
  client: null,
});

export const AuthProvider: FC<{ client: UserManager }> = ({
  client,
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const setAuthenticated = (user: User): void => {
      analytics.identify(user?.profile?.sub);
      setIsAuthenticated(true);
      setCurrentUser(user);
    };

    const unsetAuthenticated = (): void => {
      setIsAuthenticated(false);
      setCurrentUser(undefined);
    };

    client.events.addUserLoaded(setAuthenticated);
    client.events.addUserUnloaded(unsetAuthenticated);

    client
      .getUser()
      .then((user) => {
        if (!user || !user?.access_token) {
          throw new Error('No Auth User');
        }

        if ((user?.expires_at && user?.expired) || user?.expires_in < 300) {
          return client.signinSilent();
        }

        return user;
      })
      .then(setAuthenticated)

      .catch((error): Promise<void> | undefined => {
        if (error?.message === 'invalid_grant') {
          return client.signinRedirect();
        }
        if (error?.message !== 'No Auth User') {
          console.error(error);
        }

        // TODO: Need to verify this as it was breaking "consistent-return" linting rule--MJV
        return undefined;
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      client.events.removeUserLoaded(setAuthenticated);
      client.events.removeUserUnloaded(unsetAuthenticated);
    };
  }, [client]);

  return (
    <Loader loading={isLoading}>
      <AuthContext.Provider
        value={{ isAuthenticated, client, user: currentUser }}
      >
        {children}
      </AuthContext.Provider>
    </Loader>
  );
};
