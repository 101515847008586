import ListItem from 'components/anthic-core/Compounds/ListItem';
import PopoverMenu, {
  PopoverMenuContent,
} from 'components/Modules/PopoverMenu';
import { BaseSyntheticEvent, FC, memo, useCallback } from 'react';
import { FOOTER_LINK_CLICKED } from 'src/constants/analytics';
import { analytics } from 'src/utils/rudderInitialize';
import {
  StyledItem,
  StyledNavigationItemTypography,
  StyledNavigationPopoverListItem,
} from './styles';
import { NavigationMenuItemProps } from './types';

const NavigationMenuItem: FC<NavigationMenuItemProps> = ({
  children,
  menuItems,
}) => {
  const trackHandler = useCallback((e: BaseSyntheticEvent) => {
    analytics.track(FOOTER_LINK_CLICKED, {
      link: e.target.href,
    });
  }, []);

  return (
    <StyledItem forwardedAs="li" color="main">
      <PopoverMenu
        autoFocus={false}
        interactionKind="hover"
        hoverOpenDelay={100}
        hoverCloseDelay={500}
        content={
          <PopoverMenuContent>
            {menuItems.map((menuItem) => {
              const { href, ...remainingMenuItemProps } = menuItem;
              return (
                <StyledNavigationPopoverListItem
                  onClick={() => trackHandler}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  key={remainingMenuItemProps.content}
                >
                  <ListItem {...remainingMenuItemProps} />
                </StyledNavigationPopoverListItem>
              );
            })}
          </PopoverMenuContent>
        }
      >
        <StyledNavigationItemTypography variant="textButton">
          {children}
        </StyledNavigationItemTypography>
      </PopoverMenu>
    </StyledItem>
  );
};

export default memo(NavigationMenuItem);
