import { useQuery } from '@apollo/client';
import { Link as DSLink, Typography } from '@statespacelabs/anthic-core';
import { Close, Hamburger, User } from 'components/Icon';
import { memo, useCallback, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import LayoutBoundary from 'src/components/anthic-core/Compounds/LayoutBoundary';
import { JOIN_CLICKED, LOGIN_CLICKED } from 'src/constants/analytics';
import { useBreakpoints } from 'src/hooks';
import { analytics } from 'src/utils/rudderInitialize';
import { useAuthorization, useAuthUser } from 'utils/Auth';
import {
  GAME_PROFILES_MENU_ITEM_PROPS,
  TOOLS_MENU_ITEM_PROPS,
} from './constants';
import { NavigationLocalizationMenuItem } from './NavigationLocalizationMenuItem';
import NavigationMenuItem from './NavigationMenuItem';
import { USER_BALANCE_QUERY } from './queries';
import {
  StyledBackground,
  StyledButton,
  StyledDivider,
  StyledItem,
  StyledLogo,
  StyledMenu,
  StyledMobileLanguageDropdownContainer,
  StyledOptions,
  StyledWrapper,
  StyleMenuIcon,
  StyleMobileMenuIcon,
} from './styles';

const Navigation = (): JSX.Element => {
  const { isAuthenticated } = useAuthUser();
  const { signup } = useAuthorization();
  const [isOpen, setIsOpen] = useState(false);
  const { isLgScreen: isLgScreenOrSmaller } = useBreakpoints();
  const isAMatch = useRouteMatch('/academy');

  const menuIconClickHandler = useCallback(
    () => setIsOpen((prev) => !prev),
    [setIsOpen]
  );

  const loginClickHandler = useCallback(
    () => analytics.track(LOGIN_CLICKED),
    []
  );

  const joinClickHandler = useCallback(() => {
    analytics.track(JOIN_CLICKED);
    signup();
  }, [signup]);

  const MenuIconSvg = isOpen ? Close : Hamburger;

  // const { data } = useQuery(USER_SCOPES_QUERY, {
  //   errorPolicy: 'all',
  //   skip: !isAuthenticated,
  // });

  const { data: balanceData } = useQuery(USER_BALANCE_QUERY, {
    errorPolicy: 'all',
    skip: !isAuthenticated,
    pollInterval: 60 * 1000,
  });

  // const hasCreatorAccess = useMemo(
  //   () => getCreatorAccess(data?.viewer?.scopes || []),
  //   [data?.viewer?.scopes]
  // );
  const balance = Number(balanceData?.viewer?.balance || 0);

  return (
    <StyledBackground>
      <LayoutBoundary>
        <StyledWrapper>
          <Link to="/">
            <StyledLogo
              src={`${process.env.PUBLIC_URL}/fullLogo.svg`}
              alt="Playerbase Logo"
              title="Playerbase"
            />
          </Link>
          <StyledMenu $isOpen={isOpen}>
            <NavigationMenuItem menuItems={TOOLS_MENU_ITEM_PROPS}>
              Tools
            </NavigationMenuItem>

            <NavigationMenuItem menuItems={GAME_PROFILES_MENU_ITEM_PROPS}>
              Game Profiles
            </NavigationMenuItem>

            {/* {hasCreatorAccess && (
              <StyledItem>
                <DSLink
                  href="https://dashboard.playerbase.com"
                  target="_blank"
                  rel="noreferrer"
                  variant="textButton"
                >
                  Dashboard
                </DSLink>
              </StyledItem>
            )} */}
            {isAMatch && isLgScreenOrSmaller && (
              <>
                <StyledDivider />
                <StyledMobileLanguageDropdownContainer>
                  <NavigationLocalizationMenuItem />
                </StyledMobileLanguageDropdownContainer>
              </>
            )}
          </StyledMenu>
          <StyledOptions>
            {isAMatch && !isLgScreenOrSmaller && (
              <NavigationLocalizationMenuItem />
            )}
            {!isAuthenticated && (
              <>
                <StyledItem as="div">
                  <Link to="/login">
                    <DSLink
                      as="button"
                      onClick={loginClickHandler}
                      variant="button"
                    >
                      Login
                    </DSLink>
                  </Link>
                </StyledItem>

                <StyledButton onClick={joinClickHandler} color="secondary">
                  Join
                </StyledButton>
              </>
            )}
            {isAuthenticated && (
              <>
                <Typography variant="caption" color="main">
                  {balance > 0 &&
                    balance.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                </Typography>
                <StyleMenuIcon as="a" href="/account">
                  <User />
                </StyleMenuIcon>
              </>
            )}
            <StyleMobileMenuIcon onClick={menuIconClickHandler}>
              <MenuIconSvg />
            </StyleMobileMenuIcon>
          </StyledOptions>
        </StyledWrapper>
      </LayoutBoundary>
    </StyledBackground>
  );
};

export default memo(Navigation);
