import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import httpBackend from 'i18next-http-backend';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { i18nSharedConfig } from './constants';

// docs:
// https://github.com/i18next/i18next-multiload-backend-adapter
// https://github.com/i18next/i18next-http-backend
// https://github.com/i18next/i18next-browser-languageDetector
// https://react.i18next.com/
// https://www.i18next.com/

i18n
  .use(BackendAdapter)
  .use(httpBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/localization/translations/{lng}/{ns}.json',
      allowMultiLoading: true,
    },
    detection: {
      // order to determine the detection of preferred language
      order: [
        'sessionStorage',
        'navigator',
        'cookie',
        'localStorage',
        'path',
        'querystring',
        'htmlTag',
        'subdomain',
      ],
    },
    ...i18nSharedConfig,
  });

export default i18n;
