import { Section } from '@statespacelabs/anthic-core';
import Footer from 'components/Modules/Footer';
import Navigation from 'components/Modules/Navigation';
import Routes from 'components/Pages/Routes';
import { FC, memo, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import LayoutBoundary from 'src/components/anthic-core/Compounds/LayoutBoundary';
import ApplySection from 'src/components/Modules/ApplySection';
import { ApplicationLocationState } from 'src/typings/routing';
import { analytics } from 'src/utils/rudderInitialize';
import { StyledPage } from './styles';

const routesContainingApplySection = {
  homepage: '/',
  games: '/games',
  coaches: '/coaches',
  creator: '/c/:id',
  bundleInstructions: '/account/bundles/:id',
  gamePage: '/games/:id',
};
const applySectionPaths = Object.values(routesContainingApplySection);

const Layout: FC = () => {
  const history = useHistory<ApplicationLocationState | undefined>();

  useEffect(() => {
    let prevPath: string = '';
    const unlisten = history.listen((location) => {
      if (location.pathname !== prevPath) {
        prevPath = location.pathname;
        analytics.page('Internal Page Navigation', location.pathname);
      }

      const { state: locationState } = location;
      if (!locationState?.preventScroll) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    analytics.page('External Page Naviation', {
      history: history.location.pathname,
    });
  }, [history.location.pathname]);

  return (
    <>
      <Navigation />
      <StyledPage>
        <Routes />
      </StyledPage>
      <Section forwardedAs="footer">
        <LayoutBoundary>
          <Route
            path={applySectionPaths}
            exact
            component={() => <ApplySection />}
          />
          <Footer />
        </LayoutBoundary>
      </Section>
    </>
  );
};

export default memo(Layout);
