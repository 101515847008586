import { gql } from '@apollo/client';

export const USER_BALANCE_QUERY = gql`
  query UserBalance {
    viewer {
      id
      balance
    }
  }
`;

export const USER_SCOPES_QUERY = gql`
  query UserScopes {
    viewer {
      id
      scopes
    }
  }
`;
