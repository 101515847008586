export const aimlabApiHost = process.env.REACT_APP_AIMLAB_API_HOST;
export const aimlabBlogUrl = process.env.REACT_APP_AIMLAB_BLOG_URL;
export const aimlabPPUrl = process.env.REACT_APP_AIMLAB_PP_URL;
export const aimlabRestApiHost = process.env.REACT_APP_AIMLAB_REST_API_HOST;
export const aimlabSteamUrl = process.env.REACT_APP_AIMLAB_STEAM_URL;
export const aimlabTOSUrl = process.env.REACT_APP_AIMLAB_TOS_URL;
export const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
export const authHost = process.env.REACT_APP_AUTH_HOST;
export const contentfulAccessToken =
  process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
export const contentfulEnvironment =
  process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
export const contentfulSpaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const discordUrl = process.env.REACT_APP_DISCORD_URL;
export const env = process.env.NODE_ENV.toLowerCase();
export const staticHost = process.env.REACT_APP_STATIC_HOST;
export const valorantGraphQLUrl = process.env.REACT_APP_VALORANT_GRAPHQL_URL;
export const authDefaultRoute = process.env.REACT_APP_AUTH_DEFAULT_ROUTE || '';
export const loginDefaultRoute = process.env.REACT_APP_AUTH_LOGIN_ROUTE;
export const logoutDefaultRoute = process.env.REACT_APP_AUTH_LOGOUT_ROUTE;
export const stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const zendeskKey = process.env.REACT_APP_ZENDESK_KEY || undefined;
export const crowdinKey = process.env.REACT_APP_CROWDIN_KEY;
export const crowdinProjectId = process.env.REACT_APP_CROWDIN_PROJECT_ID;

export const riotLink = `https://auth.riotgames.com/authorize?redirect_uri=${authHost}/identity/riot/oauth&client_id=aimlab&response_type=code&scope=openid`;

export const discordLink = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=829388904041414677&scope=identify%20guilds&redirect_uri=${authHost}/identity/discord/oauth`;

export const isDevelopment = env === 'development';
export const isProduction = env === 'production';
