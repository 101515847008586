import * as translationResources from 'src/../public/localization/sources';
import { Language } from 'src/graphql/types';

export const DEFAULT_LANGUAGE = Language.En;

// config is shared between the main i18n config and our mock tests.
export const i18nSharedConfig = {
  // Naming is important here as these must match the Crowdin translation file names.
  // These also influence how translations are referenced in components.
  // e.g. academyHome -> academyHome.json
  // in component: t('academyHome:herobox.description')
  ns: [
    'common',
    'academy',
    'academyHome',
    'academyCourseList',
    'academyCourse',
    'academyCourseItem',
  ],
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}',
  },
};

const getAllResources = (
  allTranslationSources: Record<string, unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  const translationResourceEntries = Object.entries(allTranslationSources);
  return translationResourceEntries.reduce(
    (existingResources, [resourceName, resource]) => ({
      ...existingResources,
      [resourceName]: resource,
    }),
    {}
  );
};

export const reactI18NextTestConfig = {
  ...i18nSharedConfig,
  resources: {
    en: getAllResources(translationResources),
  },
};
