import { css } from 'styled-components';

export const propStyles =
  (propKey, variantStyles) =>
  ({ theme, ...props }) => {
    const variants = variantStyles(theme, props);
    const keyValue = props[propKey];

    if (typeof keyValue === 'boolean') {
      return variants;
    }

    if (variants[keyValue]) {
      return variants[keyValue];
    }

    return null;
  };

export const propOrValue = (propKey, defaultValue) => (props) =>
  props[propKey] || defaultValue;

/**
 * Truncates the text contained in the element and adds an ellipsis to the end.
 * @param {'none' | number} maxLines Maximum number of lines to render. 'none' unsets it.
 * @returns ThemedCssFunction<DefaultTheme>
 */
export const lineClamp = (maxLines) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 *
 * @param {number} maxLines Maximum number of lines to render.
 * @param {string} fontSize Font-size used in element measured. Must include unit. For example '18px' or '1.8rem'.
 * @param {number} lineHeight Unitless line height.
 * @returns ThemedCssFunction<DefaultTheme> representing the new height of the element.
 */
export const lineClampByLineHeight = (maxLines, fontSize, lineHeight) => css`
  height: calc(${maxLines} * ${lineHeight} * ${fontSize});
  overflow: hidden;
`;

export const breakWord = () => css`
  overflow-wrap: break-word;
  min-width: 0;
`;
