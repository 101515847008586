import { memo } from 'react';
import Popover from 'src/components/anthic-core/Units/Popover';
import { PopoverMenuProps } from './types';

const PopoverMenu: React.FC<PopoverMenuProps> = ({
  placement = 'bottom',
  transitionDuration = 0,
  ...props
}) => {
  return (
    <Popover
      placement={placement}
      transitionDuration={transitionDuration}
      {...props}
    />
  );
};

export default memo(PopoverMenu);
