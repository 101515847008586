
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CoachUnion": [
      "Creator",
      "ProGuidesCoach"
    ],
    "Content": [
      "Image",
      "Task",
      "Video"
    ],
    "CourseItemVariantRevision": [
      "CourseItemVariantRevisionDraft",
      "FinalizedCourseItemVariantRevision"
    ],
    "NotificationSourceObject": [
      "Bundle"
    ],
    "PreviewableContent": [
      "Task",
      "Video"
    ]
  }
};
      export default result;
    