import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { UserManager } from 'oidc-client';
import {
  aimlabApiHost,
  contentfulAccessToken,
  contentfulEnvironment,
  contentfulSpaceId,
  isProduction,
} from 'src/config';
import generatedIntrospection from 'src/graphql/possibleTypes';

const createClient = (
  authClient: UserManager
): ApolloClient<NormalizedCacheObject> => {
  const AUTH_ERRORS = ['SESSION_EXPIRED', 'UNAUTHENTICATED'];

  const aimlabApiLink = new HttpLink({
    uri: `${aimlabApiHost}/graphql`,
  });

  const aimlabAuthLink = setContext(async () => {
    let user;
    try {
      user = await authClient.getUser();
    } catch (error) {
      // Do nothing
    }

    return {
      headers: {
        Authorization: user?.access_token ? `Bearer ${user.access_token}` : '',
      },
    };
  });

  const contentfulHttpLink = new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${contentfulSpaceId}/environments/${contentfulEnvironment}`,
    headers: {
      Authorization: `Bearer ${contentfulAccessToken}`,
    },
  });

  const errorLink = onError(({ operation, forward }) => forward(operation));

  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: Number.POSITIVE_INFINITY,
      jitter: true,
    },
    attempts: {
      max: 2,
      retryIf: (error) =>
        !error.result?.errors?.some((item: { extensions: { code: string } }) =>
          AUTH_ERRORS.includes(item?.extensions?.code)
        ),
    },
  });

  const loggerLink = new ApolloLink((operation, forward) => {
    if (!isProduction) {
      console.info(operation.operationName, operation);
    }
    return forward(operation).map((result) => {
      if (!isProduction) {
        console.info(operation.operationName, result);
      }
      return result;
    });
  });

  const aimlabApiFlow = ApolloLink.from([
    aimlabAuthLink,
    errorLink,
    retryLink,
    loggerLink,
    aimlabApiLink,
  ]);

  const contentfulFlow = ApolloLink.from([
    errorLink,
    loggerLink,
    contentfulHttpLink,
  ]);

  const link = ApolloLink.split(
    (operation) => operation.getContext().clientName === 'contentful',
    contentfulFlow,
    aimlabApiFlow // default
  );

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Aimlab: {
          keyFields: [],
        },
        Valorant: {
          keyFields: [],
        },
        AimlabProfile: {
          keyFields: ['username'],
          merge: true,
        },
      },
    }),
    link,
  });
};

export default createClient;
