import { ApolloProvider } from '@apollo/client';
import '@blueprintjs/core/lib/css/blueprint.css';
import { Loader, theme, ThemeProvider } from '@statespacelabs/anthic-core';
import '@statespacelabs/anthic-core/dist/index.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Toaster from 'components/anthic-core/Units/Toaster';
import Layout from 'components/Pages/Page/Layout';
import { memo, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { isDevelopment, stripeKey } from 'src/config';
import { QueryParamProvider } from 'use-query-params';
import { AuthProvider, createAuthService } from 'utils/Auth';
import createClient from 'utils/createClient';
import { ZendeskChatWidget } from './components/Modules/ZendeskWebWidget';

const stripePromise = loadStripe(stripeKey as string);

const authClient = createAuthService();
const client = createClient(authClient);

const App = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <AuthProvider client={authClient}>
      <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <HelmetProvider>
                  <Helmet defaultTitle="Playerbase">
                    {isDevelopment && <meta name="robots" content="noindex" />}
                  </Helmet>
                  <Layout />
                  <Toaster />
                  <ZendeskChatWidget />
                </HelmetProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Elements>
      </ApolloProvider>
    </AuthProvider>
  </Suspense>
);

export default memo(App);
