import { memo } from 'react';
import { StyledPopoverMenuContent } from './styles';
import { PopoverMenuContentProps } from './types';

const PopoverMenuContent = ({
  children,
  ...contentProps
}: PopoverMenuContentProps): JSX.Element => {
  return (
    <StyledPopoverMenuContent
      data-testid="popover-menu"
      role="navigation"
      aria-label="popover-menu"
      {...contentProps}
    >
      {children}
    </StyledPopoverMenuContent>
  );
};

export default memo(PopoverMenuContent);
