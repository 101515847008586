import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { StyledLayoutBoundaryProps } from './types';

export const StyledLayoutBoundary = styled.div<StyledLayoutBoundaryProps>`
  margin: ${({ $margin }) => $margin || '0 553px'};
  max-width: 2734px;

  ${down('xxl')} {
    max-width: 1490px;
    margin: ${({ $margin }) => $margin || '0 215px'};
  }

  ${down('xl')} {
    margin: ${({ $margin }) => $margin || '0 120px'};
  }

  ${down('lg')} {
    max-width: 896px;
    margin: ${({ $margin }) => $margin || '0 64px'};
  }

  ${down('md')} {
    max-width: 680px;
  }

  ${down('sm')} {
    max-width: 380px;
    margin: ${({ $margin }) => $margin || '0 24px'};
  }
`;
