import playerbaseLogo from './playerbase-chat-avatar.svg';
import { WebWidgetSettings } from './zendesk-settings.types';

export const WEB_WIDGET_SETTINGS: WebWidgetSettings = {
  chat: {
    title: {
      '*': 'Customer Service',
    },
    concierge: {
      avatarPath: playerbaseLogo,
      name: 'Playerbase Team',
      title: { '*': 'A member of the team is available now' },
    },
  },
  launcher: {
    chatLabel: {
      '*': 'Chat',
    },
  },
};

export const CHAT_TAGS = ['playerbase'];
