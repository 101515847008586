import i18next from 'i18next';
import { reactI18NextTestConfig } from './constants';

const testI18n = i18next.createInstance(
  {
    ...reactI18NextTestConfig,
  },
  (err, _t): void => {
    if (err) {
      console.error(err);
    }
  }
);

export default testI18n;
